import { getBasePath } from "@/helpers/environment";
import gsap from "gsap";
import CustomEase from "gsap/CustomEase";
import { useEffect, useRef, useState } from "react";
import useMapGSAPToProgress from "@/hooks/use-map-gsap-to-progress";
import { createObject } from "./css-3d/create-object";
import { useFrame, useThree } from "@react-three/fiber";

gsap.registerPlugin(CustomEase);
CustomEase.create(
  "text-translate",
  "M0,0 C0,0.45 0.103,0.444 0.5,0.5 0.902,0.556 1,0.548 1,1"
);

const ScrollerText = ({ children, inProgress, outProgress }) => {
  const ref = useRef();
  const [object, setObject] = useState();

  useEffect(() => {
    const obj = createObject();
    obj.element.classList.add("scroller-text");
    obj.element.innerHTML = children.toString();
    setObject(obj);
  }, []);

  const { camera } = useThree();

  useFrame(() => {
    if (object) {
      object.lookAt(camera.position);
    }
  });

  const inRange = useMapGSAPToProgress(
    () => {
      const element = object.element;
      return gsap
        .timeline({ paused: true })
        .fromTo(
          object.position,
          { z: 0 },
          { ease: "text-translate", z: 100, duration: 1 },
          0
        )
        .fromTo(element, { opacity: 0 }, { opacity: 1, duration: 0.3 }, 0)
        .fromTo(element, { opacity: 1 }, { opacity: 0, duration: 0.25 }, 0.75);
    },
    inProgress,
    outProgress
  );

  return (
    <>
      {object && inRange && <primitive ref={ref} object={object}></primitive>}
    </>
  );
};

export default ScrollerText;
