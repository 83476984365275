import { useLayoutEffect, useState } from "react";
import styled from "styled-components";

export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1440,
  xxl: 1800,
};

export const queries = {};
for (const breakpoint in breakpoints) {
  queries[
    breakpoint
  ] = `@media only screen and (min-width: ${breakpoints[breakpoint]}px)`;
}

export const setBreakpoint = (breakpoint, orientation) => {
  const interleave = (definitions, values = []) => {
    const result = [];
    definitions.forEach((definition, i) => {
      if (values[i] !== undefined) {
        result.push(definition, values[i]);
      } else {
        result.push(definition);
      }
    });
    const definition = result.join("");
    return definition;
  };

  return (definitions, ...values) => {
    const screenQuery = queries[breakpoint];
    const orientationQuery = orientation
      ? `and (orientation:${orientation})`
      : "";
    const result = `${screenQuery} ${orientationQuery} {${interleave(
      definitions,
      values
    )}}`;
    return result;
  };
};

export const useBreakpoint = () => {
  const entries = Object.entries(breakpoints);
  const sorted = entries.sort((a, b) => a[1] - b[1]);
  const [currentBreakpoint, setCurrentBreakpoint] = useState({
    name: sorted[0][0],
    value: sorted[0][1],
  });

  useLayoutEffect(() => {
    const measure = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      let rawResult = sorted[0];
      const isInRange = (v, min, max) => v >= min && v < max;
      sorted.forEach((entry, i) => {
        const nextEntry =
          i + 1 < sorted.length ? sorted[i + 1] : ["max", Number.MAX_VALUE];
        if (isInRange(width, entry[1], nextEntry[1])) rawResult = entry;
      });
      return {
        name: rawResult[0],
        value: rawResult[1],
        orientation: width > height ? "landscape" : "portrait",
      };
    };

    const listener = () => {
      const result = measure();
      if (result.name !== currentBreakpoint.name) setCurrentBreakpoint(result);
    };

    setCurrentBreakpoint(measure());
    window.addEventListener("resize", listener);

    return () => {
      window.removeEventListener("resize", listener);
    };
    // TODO: Create a pattern that doesn't result in 'sorted' in the
    // deps array creating a loop
  }, [currentBreakpoint.name]);

  return currentBreakpoint;
};

export const Helper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;

  &::before {
    content: "xs";

    // TODO: create this automatically by iteraring breakpoints const
    ${setBreakpoint("sm")`
       content: "sm";
    `}

    ${setBreakpoint("md")`
       content: "md";
    `}    
    
    ${setBreakpoint("lg")`
       content: "lg";
    `}

    ${setBreakpoint("xl")`
      content: "xl";
    `};

    ${setBreakpoint("xxl")`
      content: "xxl";
    `};

    color: white;
    padding: 1rem;
  }
`;
