import { ImageLoader } from "three";

const loader = new ImageLoader();

export const load = async (path) => {
  const result = await loader.loadAsync(path);
  return result;
};

export const parse = async (loaderResult) => {
  // noop
  return loaderResult;
};

export default { load, parse };
