import React from "react";
import ReactDOM from "react-dom/client";
import App from "@/components/app";
import { getBasePath, isWordPress } from "./helpers/environment";
import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  body, html {
    margin: 0;
    padding: 0;
  }
`;

// Fonts name spaced to 'Intro' to prevent collisions with other page fonts
const Fonts = createGlobalStyle`
  @font-face {
    font-family: 'Intro_Inter';
    font-style: normal;
    font-weight: 600;
    font-display: block;
    src: url(${getBasePath()}/fonts/inter/inter-v11-latin-600.woff2) format('woff2');
  }
  @font-face {
    font-family: 'Intro_Inter';
    font-style: normal;
    font-weight: normal;
    font-display: block;
    src: url(${getBasePath()}/fonts/inter/inter-v11-latin-regular.woff2) format('woff2');
  }
`;

const root = ReactDOM.createRoot(document.getElementById("gsc-animation"));
root.render(
  <React.StrictMode>
    {!isWordPress() && <GlobalStyles />}
    <Fonts />
    <App />
  </React.StrictMode>
);
