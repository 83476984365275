import { createRoot, extend, useThree } from "@react-three/fiber";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { CSS3DRenderer } from "three/examples/jsm/renderers/CSS3DRenderer.js";

CSS3DRenderer.prototype.setPixelRatio = () => {
  /* noop */
};

const Resizer = () => {
  const { gl, camera } = useThree();
  useEffect(() => {
    const resize = () => {
      //const rect = gl.domElement.parentNode.getBoundingClientRect();
      camera.aspect = window.innerWidth / window.innerHeight;
      gl.setSize(window.innerWidth, window.innerHeight);
    };
    window.addEventListener("resize", resize);
    resize();

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  return <></>;
};

const Root = ({ children }) => {
  const root = useRef();
  const [ref, setRef] = useState();

  useEffect(() => {
    if (ref && !root.current) {
      const gl = new CSS3DRenderer();
      const root = createRoot(ref);
      root.configure({
        gl: (canvas) => {
          canvas.appendChild(gl.domElement);
          // gl.domElement.style.overflow = "visible";
          return gl;
        },
        camera: { position: [0, 0, 20] },
        size: { width: window.innerWidth, height: window.innerHeight },
      });
      root.render(<>{children}</>);
      root.current = root;
      // resize();

      return () => {
        window.removeEventListener("resize", resize);
      };
    }
  }, [ref]);

  return <div ref={setRef}></div>;
};

const CSS3DStyledDiv = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  //overscroll-behavior: none;
`;
const CSS3D = ({ children, className }) => {
  return (
    <CSS3DStyledDiv className={className}>
      <Root>
        <Resizer />
        {children}
      </Root>
    </CSS3DStyledDiv>
  );
};

export default CSS3D;
