import { useEffect, useRef, useState } from "react";
import { inRange as checkRange } from "@/math/general";
import { useFrame } from "@react-three/fiber";
import useScroller from "@/hooks/use-scroller";
import { map } from "@/math/map";
import { lerp } from "@/math/general";

const useMapGSAPToProgress = (getTimeline, inProgress, outProgress) => {
  const state = useRef({ current: 0, target: 0 });
  const [inRange, setInRange] = useState(false);

  useEffect(() => {
    const unsubscribe = useScroller.subscribe(({ progress }) => {
      const update = () => {
        const _state = state.current;
        if (checkRange(progress, inProgress, outProgress)) {
          if (!inRange) {
            if (!_state.timeline) _state.timeline = getTimeline();
            setInRange(true);
          }
          _state.target = map(
            progress,
            inProgress,
            outProgress,
            0,
            _state.timeline.duration()
          );
        } else if (inRange && !checkRange(progress, inProgress, outProgress)) {
          setInRange(false);
        }
      };
      update();
    });
    return unsubscribe;
  }, [inRange, getTimeline]);

  useFrame(() => {
    if (inRange) {
      const _state = state.current;
      _state.current = lerp(_state.current, _state.target, 0.6);
      _state.timeline.seek(_state.current);
    }
  });
  return inRange;
};

export default useMapGSAPToProgress;
