import create from "zustand";
import { persist } from "zustand/middleware";

const store = (set) => ({
  anchorId: null,
  setAnchorId: (anchorId) => set(() => ({ anchorId })),
  isFirstSession: true,
  setIsFirstSession: (isFirstSession) => set(() => ({ isFirstSession })),
});

// Initialise the store, with isFirstSession state persisting across browser
// sessions
const useSkipButton = create(persist(store), {
  name: "skip-button",
  partialize: ({ isFirstSession }) => ({ isFirstSession }),
});

export default useSkipButton;
