import { createObject } from "@/components/css-3d/create-object";
import useScroller from "@/hooks/use-scroller";
import useThrottledFrame from "@/hooks/use-throttled-frame";
import { useImageSequence } from "@/services/image-sequence";
import loader from "@/services/load-image";
import { useThree } from "@react-three/fiber";
import { useCallback, useEffect, useRef, useState } from "react";

const ScrollerPlane = ({ path, numFrames, orientation }) => {
  // Internal state that won't re-render React
  const state = useRef({});

  // Other reactive state
  const [object, setObject] = useState();
  const { gl } = useThree();

  // Create 3D objects on mount, in a very-non React way
  useEffect(() => {
    if (orientation) {
      const resolution = {
        portrait: { width: 1220, height: 1920 },
        landscape: {
          width: 1920,
          height: 1080,
        },
      }[orientation];
      const obj = createObject();
      obj.position.set(0, 0, -400);
      const scale = orientation === "portrait" ? 1 : 2;
      obj.scale.set(scale, scale, 1);
      const canvas = document.createElement("canvas");
      canvas.width = resolution.width;
      canvas.height = resolution.height;
      obj.element.appendChild(canvas);
      state.current.canvas = canvas;
      setObject(obj);
    }
  }, []);

  // A store for the preloaded image sequence
  const sequence = useImageSequence(path, numFrames, gl, loader, 60);

  // Subscribe to scroll events, using Zustand's transient events so that
  // React doesn't re-render
  useEffect(() => {
    const unsubscribe = useScroller.subscribe(({ progress }) => {
      const updateState = () => {
        if (progress != state.current.progress) {
          state.current = Object.assign(state.current, {
            progress,
            needsUpdate: true,
          });
        }
      };
      updateState();
    });
    return unsubscribe;
  }, []);

  const setCanvasByProgress = useCallback(
    async (progress) => {
      const { canvas } = state.current;
      if (object && canvas) {
        const context = canvas.getContext("2d");
        const { getImageByProgress } = sequence;
        const image = await getImageByProgress(progress);
        context.drawImage(
          image,
          0,
          0,
          image.naturalWidth,
          image.naturalHeight,
          0,
          0,
          canvas.width,
          canvas.height
        );
      }
    },
    [object]
  );

  // Initial update on mount
  /*
     useLayoutEffect(() => {
    let timeout;
    if (!!sequence) {
      timeout = setTimeout(() => {
        setCanvasByProgress(0, true);
        // setShow(true);
      }, 1);
    }
    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [sequence, setCanvasByProgress]);
  */

  const update = useCallback(() => {
    if (sequence) {
      const { progress } = state.current;
      setCanvasByProgress(progress);
    }
  }, [sequence, setCanvasByProgress]);

  // Frame loop
  useThrottledFrame(() => {
    const { needsUpdate } = state.current;
    if (needsUpdate) {
      update();
      state.current.needsUpdate = false;
    }
  }, 30);

  return <>{object && <primitive object={object}></primitive>}</>;
};

export default ScrollerPlane;
