const getBasePath = () => {
  if (window.gscAnimation?.pluginsUrl)
    return `${window.gscAnimation.pluginsUrl}/build`;
  return ".";
};

const isWordPress = () => !!window.gscAnimation;

const domMode = () => {
  return window.location.href.includes("force-dom");
};

export { getBasePath, isWordPress, domMode };
