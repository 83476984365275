import { useFrame } from "@react-three/fiber";
import { useRef } from "react";

const useThrottledFrame = (callback, fps = 25, priority = 0) => {
  const state = useRef({
    fpsInterval: 1000 / fps,
    then: performance.now(),
    startTime: performance.now(),
    now: 0,
    elapsed: 0,
    frame: 0,
  });

  useFrame((three, delta) => {
    const s = state.current;
    s.now = performance.now();
    s.elapsed = s.now - s.then;
    if (s.elapsed > s.fpsInterval) {
      s.then = s.now - (s.elapsed % s.fpsInterval);
      callback(three, delta, s.frame);
      s.frame++;
    }
  }, priority);
};

export default useThrottledFrame;
