import { getBasePath } from "@/helpers/environment";
import { supportsWebP, supportsAvif } from "@/helpers/feature-detect";
import { useEffect, useState } from "react";
import RestingPlane from "./resting-plane";
import ScrollerPlane from "./scroller-plane";
import { useBreakpoint, breakpoints } from "@/breakpoints";
import { useMemo } from "react";
import usePrevious from "../../hooks/use-previous";

const NUM_FRAMES = 720;

const ImageSequencePlane = () => {
  const [extention, setExtention] = useState();
  const { orientation, value } = useBreakpoint();
  const previousOrientation = usePrevious(orientation);

  // if we change from landscape to portrait or vice versa... just dump the
  // whole shebang and reload, rather than unloading assets, and reloading new
  // assets for the aspect ratio
  useEffect(() => {
    if (!previousOrientation) return;
    if (previousOrientation != orientation) window.location.reload();
  }, [orientation, previousOrientation]);

  useEffect(() => {
    const detect = async () => {
      if (await supportsAvif()) return setExtention("avif");
      if (await supportsWebP()) return setExtention("webp");
      setExtention("jpg");
    };
    detect();
  }, []);

  const planes = useMemo(() => {
    if (!extention || !orientation) return null;
    const resolutionNames =
      value > breakpoints.sm
        ? { max: "high", min: "medium" }
        : { max: "medium", min: "low" };
    return (
      <>
        <RestingPlane
          orientation={orientation}
          numFrames={NUM_FRAMES}
          // that's a big string interpolation! An image name will resolve to something like landscape-high-1.avif
          path={`${getBasePath()}/scroll-content/images/${orientation}-${
            resolutionNames.max
          }-%frame%.${extention}`}
        />
        <ScrollerPlane
          orientation={orientation}
          numFrames={NUM_FRAMES}
          path={`${getBasePath()}/scroll-content/images/${orientation}-${
            resolutionNames.min
          }-%frame%.${extention}`}
        />
      </>
    );
  }, [orientation, extention]);

  return <>{planes}</>;
};

export default ImageSequencePlane;
