import useScroller from "@/hooks/use-scroller";
import useThrottledFrame from "@/hooks/use-throttled-frame";
import { useThree } from "@react-three/fiber";
import gsap from "gsap";
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { createObject } from "@/components/css-3d/create-object";
import loader from "@/services/load-image";
const { load } = loader;

const getImagePathByProgress = (path, numFrames, progress) => {
  const index = Math.floor(progress * numFrames);
  const result = path.replace("%frame%", index + 1);
  return result;
};

const RestingPlane = ({ path, numFrames, orientation }) => {
  // Internal state that won't re-render React
  const state = useRef({
    timeline: gsap.timeline(),
  });

  const { gl } = useThree();
  const [object, setObject] = useState();

  // Create 3D objects on mount, in a very-non React way
  useEffect(() => {
    if (orientation) {
      const resolution = {
        portrait: { width: 1220, height: 1920 },
        landscape: {
          width: 1920,
          height: 1080,
        },
      }[orientation];
      const obj = createObject();
      obj.position.set(0, 0, -399);
      const scale = orientation === "portrait" ? 1 : 2;
      obj.scale.set(scale, scale, 1);
      const canvas = document.createElement("canvas");
      canvas.width = resolution.width * 2;
      canvas.height = resolution.height * 2;
      canvas.style.transform = "scale(0.5)";
      obj.element.appendChild(canvas);
      state.current.canvas = canvas;
      setObject(obj);
    }
  }, [orientation]);

  // Subscribe to scroll events, using Zustand's transient events so that
  // React doesn't re-render
  useEffect(() => {
    const unsubscribe = useScroller.subscribe(({ progress }) => {
      const updateState = () => {
        if (progress != state.current.progress) {
          state.current = Object.assign(state.current, {
            progress,
            needsUpdate: true,
          });
        }
      };
      updateState();
    });
    return unsubscribe;
  }, []);

  const setCanvasByProgress = useCallback(
    async (progress) => {
      const { canvas } = state.current;
      if (object && canvas) {
        const context = canvas.getContext("2d");
        const image = await load(
          getImagePathByProgress(path, numFrames, progress),
          gl
        );
        context.drawImage(
          image,
          0,
          0,
          image.naturalWidth,
          image.naturalHeight,
          0,
          0,
          canvas.width,
          canvas.height
        );
        fadeIn();
      }
    },
    [object]
  );

  // Initial update
  useEffect(() => {
    setCanvasByProgress(0);
  }, [setCanvasByProgress]);

  const fadeIn = useCallback(
    (progress) => {
      // object && object.visible = true;
      if (object) {
        object.visible = true;
        let { timeline } = state.current;
        if (timeline) timeline.clear();
        timeline = gsap
          .timeline()
          .fromTo(
            object.element,
            { autoAlpha: 0 },
            { autoAlpha: 1, duration: 0.33, ease: "linear" }
          );
      }
    },
    [object]
  );

  // Update the 'high quality' resting plane when the user stops scrolling (note
  // this is a debounced function that will noop within a given interval)
  const update = useCallback(
    debounce(
      () => {
        const { progress } = state.current;
        setCanvasByProgress(progress);
      },
      333,
      { trailing: true }
    ),
    [setCanvasByProgress]
  );

  // Frame loop
  useThrottledFrame(() => {
    const { needsUpdate } = state.current;
    if (needsUpdate) {
      // Hide the image at the start of an update
      object.visible = false;
      update();
      state.current.needsUpdate = false;
    }
  });

  // Create geometries at a 16:9 ratio
  return <>{object && <primitive object={object}></primitive>}</>;
};

export default RestingPlane;
