import { useFrame } from "@react-three/fiber";
import React, { useCallback, useEffect, useRef } from "react";
import { MathUtils, Vector3 } from "three";
import { map } from "@/math/map";
import { clamp } from "@/math/general";

const hasMouse = () => matchMedia("(pointer:fine)").matches;

const CameraController = () => {
  // Move the camera with the mouse
  const mouseCoords = useRef({ x: 0, y: 0 });

  useEffect(() => {
    if (hasMouse()) {
      const listener = (e) => {
        mouseCoords.current = {
          x: map(e.clientX, 0, window.innerWidth, -1, 1),
          y: map(e.clientY, 0, window.innerHeight, 1, -1),
        };
      };
      document.body.addEventListener("mousemove", listener);
      return () => {
        document.body.removeEventListener("mousemove", listener);
      };
    }
  }, []);

  useEffect(() => {
    // Even if there is a gyro, if a mouse is present don't animate the gyro
    // (eg. a docked Surface)
    if (!hasMouse()) {
      if (
        window.DeviceMotionEvent &&
        typeof window.DeviceMotionEvent.requestPermission === "function"
      ) {
        // Do we want to do anything for iOS?
      } else {
        const listener = (e) => {
          const x = clamp(map(e.gamma, -10, 10, 1, -1), -1, 1);
          const y = clamp(map(e.beta, 5, 85, -1, 1), -1, 1);
          mouseCoords.current = {
            x,
            y,
          };
        };
        window.addEventListener("deviceorientation", listener);
        return () => {
          window.removeEventListener("deviceorientation", listener);
        };
      }
    }
  }, []);

  const update = useCallback((three, delta) => {
    const { camera } = three;
    const { x, y } = mouseCoords.current;
    const tPosition = 1 - Math.pow(0.05, delta);
    /// const tRotation = 1 - Math.pow(0.1, delta);
    camera.position.x = MathUtils.lerp(camera.position.x, x * -45, tPosition);
    camera.position.y = MathUtils.lerp(camera.position.y, y * -45, tPosition);
    camera.position.z = 550;
    camera.lookAt(
      new Vector3(camera.position.x * -0.25, camera.position.y * -0.15, 0)
    );
  }, []);

  useFrame(update);
  return <></>;
};

export default CameraController;
