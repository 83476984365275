import { memoize } from "lodash";

// https://stackoverflow.com/questions/5573096/detecting-webp-support
// technically doesn't need async, but is consistent with AVIF interface which
// returns a promise
export const supportsWebP = /* memoize */ async () => {
  const el = document.createElement("canvas");
  if (!!(el.getContext && el.getContext("2d"))) {
    return el.toDataURL("image/webp").indexOf("data:image/webp") === 0;
  } else {
    return false;
  }
};

export const supportsAvif = /* memoize */ () => {
  return new Promise((resolve) => {
    const image = new Image();
    image.onload = () => resolve(true);
    image.onerror = () => resolve(false);
    image.src =
      "data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUIAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAAB0AAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAIAAAACAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgQ0MAAAAABNjb2xybmNseAACAAIAAYAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAACVtZGF0EgAKCBgANogQEAwgMg8f8D///8WfhwB8+ErK42A=";
  });
};
