import { throttle } from "lodash";
import { useLayoutEffect, useState } from "react";
import styled from "styled-components";
import { map } from "@/math/map";
import { clamp } from "@/math/general";
import useScroller from "@/hooks/use-scroller";

const THROTTLE_RATE = 1000 / 25;

const StyledDiv = styled.div`
  position: relative;
  height: ${(props) => props.numScreens * 100}vh;
  width: 100%;
  .debug {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    color: white;
    font-family: sans-serif;
  }
`;

const Scroller = ({ numScreens = 20, children, debug }) => {
  const setProgress = useScroller((state) => state.setProgress);
  const progress = useScroller((state) => state.progress);
  const [ref, setRef] = useState();

  useLayoutEffect(() => {
    if (ref) {
      const top = ref.offsetTop;
      const bottom = top + ref.clientHeight;
      const update = throttle(() => {
        const progress = map(
          window.scrollY,
          top,
          bottom - window.innerHeight,
          0,
          1
        );
        setProgress(clamp(progress, 0, 1));
      }, THROTTLE_RATE);
      update();
      document.addEventListener("scroll", update, { passive: true });
      return () => {
        document.removeEventListener("scroll", update, { passive: true });
      };
    }
  }, [ref]);

  return (
    <StyledDiv ref={setRef} numScreens={numScreens}>
      {debug && <div className="debug">{progress}</div>}
      {children}
    </StyledDiv>
  );
};

export default Scroller;
