import React, { useEffect, useState } from "react";
import useSkipButton from "./store";

const SkipButtonAnchor = () => {
  const setAnchorId = useSkipButton((state) => state.setAnchorId);

  const [anchorRef, setAnchorRef] = useState();
  useEffect(() => {
    if (anchorRef) {
      anchorRef.id = `anchor-${Math.floor(Math.random() * 9999999)}`;
      setAnchorId(anchorRef.id);
    }
  }, [anchorRef]);

  return (
    <div
      style={{ visibility: "hidden" }}
      ref={setAnchorRef}
      role="presentation"
    ></div>
  );
};

export default SkipButtonAnchor;
