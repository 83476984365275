import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useWPHost from "../../hooks/use-wp-host";
import { ReactComponent as SVG } from "./skip.svg";
import useSkipButton from "./store";

const StyledButton = styled.button`
  position: absolute;
  height: 1.75rem;
  bottom: 2rem;
  right: 2rem;
  width: 5rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  & svg {
    object-fit: cover;
    width: 100%;
    height: 100%;
    & circle {
      transition: opacity 0s 0.33s, stroke-dasharray 0.33s;
      stroke-dasharray: 0 100;
      opacity: 0;
    }
    & #arrows {
      transition: transform 0.15s;
      transform: translateX(-5%);
    }
  }

  &:hover svg {
    & circle {
      transition: opacity 0.05s, stroke-dasharray 0.33s;
      stroke-dasharray: 100 0;
      opacity: 1;
    }
    & #arrows {
      transform: translateX(0%);
    }
  }
`;

const SkipButton = () => {
  const anchorId = useSkipButton((state) => state.anchorId);
  const [shouldShow, setShouldShow] = useState(false);

  useEffect(() => {
    const unsubscribe = useSkipButton.subscribe(
      ({ isFirstSession, setIsFirstSession }) => {
        if (isFirstSession) {
          unsubscribe();
          setIsFirstSession(false);
        } else {
          setShouldShow(true);
        }
      }
    );
    return unsubscribe;
  }, []);

  const { onSkip } = useWPHost();
  const skip = () => {
    if (anchorId) {
      const el = document.getElementById(anchorId);
      el.scrollIntoView({ behavior: "smooth" });
      onSkip();
    }
  };

  return (
    <>
      {shouldShow && (
        <StyledButton onClick={skip} shou>
          <SVG />
        </StyledButton>
      )}
    </>
  );
};

export default SkipButton;
