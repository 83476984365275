import Loader from "@/components/loader";
import Progress from "@/components/progress";
import Scroller from "@/components/scroller";
import HarrisonIntro from "@/components/harrison-intro";
import CSS3D from "@/components/css-3d/renderer";
import { Suspense, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { setBreakpoint } from "@/breakpoints";
import Stickyfill from "stickyfilljs";
import platform from "platform";
import { SkipButtonAnchor, SkipButton } from "./skip-button";
import useWPHost from "../hooks/use-wp-host";

const setDocumentHeight = () => {
  document.documentElement.style.setProperty(
    "--document-height",
    `${window.innerHeight}px`
  );
};
window.addEventListener("resize", setDocumentHeight);
setDocumentHeight();

const StyledDiv = styled.div`
  font-family: "Intro_Inter", sans-serif;
  font-weight: bold;
  position: relative;
  width: 100%;
  height: 100%;
  color: white;

  &,
  & * {
    box-sizing: border-box;
  }

  /*   & > * {
    all: initial;
  } */

  & > .sticky {
    position: sticky;
    top: 0;
    width: 100%;
    height: var(--document-height);
    background: linear-gradient(45.67deg, #1402a1 48.74%, #0c70f6 87.55%);
  }

  & > .sticky:after {
    content: "";
    display: table;
  }

  & > .text-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    .text {
      margin: 33vh 0;
    }
  }

  .scroller-text {
    color: white;
    text-align: center;
    font-size: 2.8rem;
    position: relative;
    display: block;
    width: 300px;
    text-rendering: optimizeSpeed;
    will-change: transform;
    line-height: 100%;
    ${setBreakpoint("md", "portrait")`
      width: 500px;
    `}
    ${setBreakpoint("md", "landscape")`
      font-size: 5.5rem;
      width: 1000px;
    `};
  }
`;

window.onbeforeunload = function () {
  window.scrollTo(0, 0);
};

const App = () => {
  const { initialise } = useWPHost();
  initialise();

  const stickyRef = useRef();

  // Firefox's sticky implementation is quite broken when combined with CSS
  // transform content
  useEffect(() => {
    if (platform.name === "Firefox" && stickyRef.current) {
      const el = stickyRef.current;
      Stickyfill.forceSticky();
      Stickyfill.addOne(el);
      return () => {
        Stickyfill.removeOne(el);
      };
    }
  }, []);

  return (
    <>
      <Scroller numScreens={35}>
        <StyledDiv>
          <div ref={stickyRef} className="sticky">
            <Suspense fallback={<Loader />}>
              <CSS3D>
                <HarrisonIntro />
              </CSS3D>
              <SkipButton />
              <Progress />
            </Suspense>
          </div>
        </StyledDiv>
      </Scroller>
      <SkipButtonAnchor />
    </>
  );
};

export default App;
