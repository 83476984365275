import styled from "styled-components";
import useScroller from "@/hooks/use-scroller";
import { useEffect, useRef } from "react";
import { lerp } from "@/math/general";

const StyledDiv = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 0.8vh;
  & .progress-bar {
    width: 100%;
    height: 100%;
    transform-origin: top left;
    transform: scaleX(0);
    background-color: #00f8fe;
  }
`;

const Progress = () => {
  const ref = useRef();
  useEffect(() => {
    let updating = true;
    let _progress = 0;
    let _targetProgress = 0;
    const unsubscribe = useScroller.subscribe(({ progress }) => {
      _targetProgress = progress;
    });
    const update = () => {
      if (ref.current) {
        _progress = lerp(_progress, _targetProgress, 0.1);
        ref.current.style.transform = `scaleX(${_progress})`;
      }
      if (updating) requestAnimationFrame(update);
    };
    update();
    return () => {
      unsubscribe();
      updating = false;
    };
  }, []);

  return (
    <StyledDiv role="presentation">
      <div ref={ref} className="progress-bar"></div>
    </StyledDiv>
  );
};

export default Progress;
