import { useEffect } from "react";
import useScroller from "./use-scroller";

let initialised = false;
let complete = false;
useScroller.subscribe((state) => {
  if (state.progress < 1 && complete) {
    complete = false;
    window.gscAnimComplete && window.gscAnimComplete(complete);
  } else if (state.progress >= 1 && !complete) {
    complete = true;
    window.gscAnimComplete && window.gscAnimComplete(complete);
  }
});

const useWPHost = () => {
  const initialise = () => {
    if (!initialised) {
      window.gscAnimInit && window.gscAnimInit();
      initialised = true;
    }
  };

  const onSkip = () => {
    window.gscAnimSkip && window.gscAnimSkip();
  };

  return { initialise, onSkip };
};

export default useWPHost;
