import ImageSequence from "@/components/image-sequence-plane";
import ScrollerText from "@/components/scroller-text";
import CameraController from "./camera-controller";

const HarrisonIntro = () => {
  return (
    <>
      <CameraController />
      <ScrollerText inProgress={0.07} outProgress={0.17}>
        {"Hello, we’re harrison.ai"}
      </ScrollerText>
      <ScrollerText inProgress={0.17} outProgress={0.27}>
        {"a healthcare technology company"}
      </ScrollerText>
      <ScrollerText inProgress={0.3} outProgress={0.4}>
        {"combining human intelligence"}
      </ScrollerText>
      <ScrollerText inProgress={0.46} outProgress={0.56}>
        {"with artificial intelligence."}
      </ScrollerText>
      <ScrollerText inProgress={0.66} outProgress={0.76}>
        {"We’re building a range of ventures"}
      </ScrollerText>
      <ScrollerText inProgress={0.76} outProgress={0.86}>
        {"to change the face of healthcare"}
      </ScrollerText>
      <ScrollerText inProgress={0.87} outProgress={0.92}>
        {"and benefit millions of patients every day."}
      </ScrollerText>
      <ImageSequence />
    </>
  );
};

export default HarrisonIntro;
