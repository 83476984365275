import { useState } from "react";
import usePromise from "react-promise-suspense";
import defaultLoader from "@/services/load-image";
import PQueue from "p-queue";

const concurrency = 40;
const priorityHigh = Number.MAX_VALUE;
const queue = new PQueue({ concurrency });

const imageSequence = (pathTemplate, numFrames, loader, gl) => {
  let images;
  let preloadStarted = false;

  const checkLoaded = (numFrames) => {
    const loaded = Array.from(images.values())
      .slice(0, numFrames)
      .map((image) => image.loaded);

    let allLoaded = true;
    loaded.forEach((l) => {
      if (l !== true) allLoaded = false;
    });
    return allLoaded;
  };

  const preloadImages = async () => {
    if (!preloadStarted) {
      const paths = new Array(numFrames).fill(null).map((v, i) => {
        return [
          pathTemplate.replace("%frame%", i + 1),
          { image: null, loaded: false },
        ];
      });
      images = new Map(paths);
      let i = 0;
      for (const [path] of images.entries()) {
        // set images with a lower index to a higher priority (ie. the images added first to the queue are prioritised first)
        const priority = images.size - i;
        (async () => {
          await queue.add(
            async () => {
              const image = await loader.load(path, gl);
              images.set(path, { image, loaded: true });
            },
            { priority }
          );
        })();
        i++;
      }
      preloadStarted = true;
    }
  };

  const getImageByProgress = async (progress) => {
    const index = Math.floor(progress * numFrames);
    const imageInfo = Array.from(images.values())[index];
    if (imageInfo && imageInfo.loaded) {
      return await loader.parse(imageInfo.image);
    } else {
      const path = pathTemplate.replace("%frame%", index);
      const result = await queue.add(
        async () => {
          const image = await loader.load(path, gl);
          images.set(path, { image, loaded: true });
          return image;
        },
        { priority: priorityHigh }
      );
      return await loader.parse(result);
    }
  };
  preloadImages();
  return { getImageByProgress, checkLoaded };
};

export const useImageSequence = (
  path,
  numFrames,
  gl,
  loader = defaultLoader,
  suspendUntilNumFramesLoaded = 25
) => {
  const [sequence] = useState(imageSequence(path, numFrames, loader, gl));
  const result = usePromise(() => {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        const { checkLoaded } = sequence;
        const _loaded = checkLoaded(suspendUntilNumFramesLoaded);
        if (_loaded) {
          clearInterval(interval);
          resolve(Object.assign({}, sequence, { loaded: Math.random() }));
        }
      }, 10);
    });
  }, []);
  return result;
};

export default imageSequence;
